var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sopakit-items"},[_c('page-header',[_c('div',{staticClass:"mobile"},[_c('div',{staticClass:"page-header-inner-wrapper"},[_c('h1',[_vm._v("소파킷")]),_c('span',{staticClass:"seperator"},[_vm._v("|")]),_c('b-link',{staticClass:"emp header-link"},[_vm._v(" 키워드 ")]),_c('b-link',{staticClass:"header-link",attrs:{"to":{ name: 'SopakitAllItems' }}},[_vm._v(" 상품 목록 ")])],1)]),_c('div',{staticClass:"desktop"},[_c('div',{staticClass:"page-header-inner-wrapper"},[_c('h1',[_vm._v("소파킷")]),_c('span',{staticClass:"seperator"},[_vm._v("|")]),_c('b-link',{staticClass:"emp header-link"},[_vm._v(" 키워드 ")]),_c('b-link',{staticClass:"header-link",attrs:{"to":{ name: 'SopakitAllItems' }}},[_vm._v(" 상품 목록 ")]),_c('div',{staticClass:"search-box"},[_c('b-form-input',{staticClass:"search-input",attrs:{"placeholder":"검색"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnterKeyupped($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-button',{staticClass:"search-button",on:{"click":_vm.searchButtonClicked}},[_c('font-awesome-icon',{staticClass:"search-icon",attrs:{"icon":['fas', 'search']}})],1)],1)],1)])]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"items flex-fill"},[_c('div',{staticClass:"items-inner-wrapper"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.keywords),function(keyword,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-slide-inner-wrapper"},[_c('div',{staticClass:"mobile-main-mock"},[_c('div',{staticClass:"image-resizer"},[_c('div',{staticClass:"inner",style:({
                          'background-image': ("url('" + (keyword.image_url) + "')"),
                        })})])]),_c('div',{staticClass:"title-section"},[_c('div',{staticClass:"title-number"},[_vm._v(_vm._s(keyword.num))]),_c('div',{staticClass:"title-seperator"}),_c('div',{staticClass:"title-text"},[_vm._v(_vm._s(keyword.title))]),_c('div',{staticClass:"title-products"},[_vm._v(" "+_vm._s(keyword.products .map(function (product) { return product.related_film ? product.related_film.title : null; } ) .join(', '))+" ")]),_c('div',{staticClass:"title-year"},[_vm._v(_vm._s(keyword.year)+".")])]),_c('div',{staticClass:"mobile-title-products"},[_vm._v(" "+_vm._s(keyword.products .map(function (product) { return product.related_film ? product.related_film.title : null; } ) .join(', '))+" ")]),_c('div',{staticClass:"content-section"},[_c('div',{staticClass:"content-main"},[_c('div',{staticClass:"main-mock"},[_c('b-img',{attrs:{"src":keyword.image_url}})],1),_c('div',{staticClass:"main-text",domProps:{"innerHTML":_vm._s(keyword.description.replace(/\n/g, '<br />'))}})]),_c('div',{staticClass:"content-product"},[_c('div',{staticClass:"product-title"},[_vm._v("상품 보기")]),_c('div',{staticClass:"product-items"},_vm._l((keyword.products),function(product,productIndex){return _c('div',{key:productIndex,staticClass:"product-item"},[_c('b-link',{staticClass:"product-img-link",attrs:{"to":{
                              name: 'SopakitDetail',
                              params: { id: product.id },
                            }}},[_c('div',{staticClass:"product-img",style:({
                                'background-image': ("url(" + (product.featured_image_url) + ")"),
                              })})]),_c('div',{staticClass:"product-film-title"},[_c('b-link',{attrs:{"to":{
                                name: 'SopakitDetail',
                                params: { id: product.id },
                              }}},[_vm._v(" "+_vm._s(product.name))])],1)],1)}),0)])])])])}),_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"last-page"},[_c('div',{staticClass:"no-keyword-products-wrapper"},[_c('div',{staticClass:"no-keyword-header"},[_vm._v("SPECIALS")]),_c('div',{staticClass:"no-keyword-products"},_vm._l((_vm.noKeywordProducts),function(product){return _c('div',{key:product.id,staticClass:"no-keyword-product"},[_c('div',{staticClass:"no-keyword-featured-image"},[_c('b-link',{attrs:{"to":{
                              name: 'SopakitDetail',
                              params: { id: product.id },
                            }}},[_c('b-img',{attrs:{"src":((product.featured_image_url) + "?size=common"),"alt":product.featured_image_alt}})],1)],1),_c('div',{staticClass:"no-keyword-text"},[_c('div',{staticClass:"no-keyword-title"},[_c('b-link',{attrs:{"to":{
                                name: 'SopakitDetail',
                                params: { id: product.id },
                              }}},[_vm._v(" "+_vm._s(product.name)+" ")])],1),_c('div',{staticClass:"no-keyword-meta"},[_vm._v(" "+_vm._s(product.c_date)+" "+_vm._s(product.side_phrase)+" ")])])])}),0)]),_c('div',{staticClass:"footer-box"},[_c('sopakit-list-footer-info',{staticClass:"footer-box-content",attrs:{"right":""}})],1)])])],2),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"swiper-button-prev"},[_c('left-big-arrow')],1),_c('div',{staticClass:"swiper-button-next"},[_c('right-big-arrow')],1)])])]),_c('div',{staticClass:"fixed-wave-container-wrapper lg:Thidden"},[_c('div',{staticClass:"fixed-wave-container"},[_c('b-img',{class:{ 'swiper-touching': _vm.swiperTouching },style:({ transform: ("translateX(" + _vm.waveTranslateX + "px)") }),attrs:{"src":require("@/assets/wave.png")}})],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary"},[_c('p',[_vm._v(" 영화배달서비스"),_c('br',{staticClass:"desktop"}),_vm._v(" 소파킷은 "),_c('br',{staticClass:"desktop"}),_vm._v("새로운 만남의"),_c('br',{staticClass:"desktop"}),_vm._v(" 시도로, 대안적 배급 "),_c('br',{staticClass:"desktop"}),_vm._v("서비스입니다."),_c('br',{staticClass:"desktop"}),_vm._v(" 매년 하나의 키워드로 "),_c('br',{staticClass:"desktop"}),_vm._v("영화를 선정하여"),_c('br',{staticClass:"desktop"}),_vm._v(" 소개합니다. ")])])}]

export { render, staticRenderFns }