<template>
  <svg class="xflip" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.46 58.27">
  <g class="a">
    <polyline class="b" points="15.7 57.88 0.95 29.14 15.7 0.39" />
  </g>
</svg>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.xflip {
  transform: scaleX(-1);
}

.a {
  opacity: 0.5;
}

.b {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
  stroke-width: 1.7px;
}

</style>
<style>

</style>